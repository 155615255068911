import React from 'react';
import { FilePond } from 'react-filepond';
import axios from 'axios';
import { BarLoader } from 'react-spinners';
import { isEqual } from 'lodash';

import store from '@stores';
import { withStore } from '@application/stores/withStore';

import '../styles/UploadDownloadUI.scss';
import 'filepond/dist/filepond.min.css';

export interface UploadDownloadUIProps {
  store: typeof store;
  index: any;
  value: undefined | string;
  template: any;
  onChange(value, index?): void;
}

interface UploadedFile {
  fileId: string;
  originalName: string;
  size?: number;
}

export interface UploadDownloadUIState {
  isLoadingFileData: boolean;
  currentFile: UploadedFile;
}

class UploadDownloadUI
  extends React.Component<UploadDownloadUIProps, UploadDownloadUIState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoadingFileData: true,
      currentFile: undefined,
    };
  }

  private getUrlFromToolTemplate = () => {
    const { template } = this.props;
    const toolConfigStringified = template.slice('file:'.length);
    const { url } = JSON.parse(toolConfigStringified);
    return url;
  }

  private getFileConfigFromProps = () => {
    const { value } = this.props;

    if (!value) {
      return undefined;
    }

    const toolConfigStringified = value.slice('file:'.length);
    const { fileId, originalName } = JSON.parse(toolConfigStringified);

    return {
      fileId,
      originalName,
    };
  }

  private serializeFileNameConfig = () => {
    const { currentFile } = this.state;

    if (!currentFile) {
      return undefined;
    }

    const newToolConfig = `file:${JSON.stringify(currentFile, null, 2)}`;

    return newToolConfig;
  }

  filePondRef: any = React.createRef();
  async componentDidMount() {
    await this.checkUploadedFile();
    this.setState({ isLoadingFileData: false });
  }

  /**
   * Detect and Display the already uploaded Static Gtfs.
   */
  checkUploadedFile = async () => {
    const { pages, api: { http } } = this.props.store;

    const feedId = pages.gtfs.getCurrentFeedId();
    const url = this.getUrlFromToolTemplate();
    const currentFileConfig = this.getFileConfigFromProps();

    const requestConfig = {
      url,
      method: 'head',
      params: {
        feedId,
        fileId: currentFileConfig && currentFileConfig.fileId,
      },
    };

    try {
      const { headers } = await http.authenticatedRequest(requestConfig);

      const currentFileOriginalName = currentFileConfig
        ? currentFileConfig.originalName
        : headers['x-file-id'];

      const currentFileSize = headers['content-length'];

      const currentFile = {
        originalName: currentFileOriginalName,
        fileId: headers['x-file-id'],
        size: currentFileSize,
      };

      const filePond = this.filePondRef.current;
      filePond.addFile(currentFileOriginalName, {
        type: 'local',
        index: 0,
        file: {
          name: currentFileOriginalName,
          size: currentFileSize,
          type: 'application/zip',
          isUploaded: true,
        },
      });

      await this.setState({ currentFile });

    } catch (err) {
      console.log(err); // tslint:disable-line no-console
    }
  }

  /**
   * Upload the File to the server.
   * NB: usualy the file is uploaded to a temp folder first.
   * In the case of Static GTFSs, the temporary file is moved when the user saves the JSON extension.
   */
  uploadFile = (fieldName, file, metadata, load, error, progress, abort) => {
    const { http } = this.props.store.api;

    const url = this.getUrlFromToolTemplate();
    const source = axios.CancelToken.source();

    const formData = new FormData();
    formData.append('file', file, file.name);

    const uploadConfig = {
      url,
      cancelToken: source.token,
      method: 'post',
      data: formData,
    };

    (async () => {
      try {
        const { data } = await http.authenticatedRequest(uploadConfig);

        const currentFile = {
          fileId: data.file.fileId,
          originalName: file.name,
        };

        await this.updateCurrentFileState(currentFile);

        load(currentFile.fileId);

      } catch (err) {
        if (!err.response) {
          error('Network Error: connection failed');
          return;
        }
        const errMessage = err.response.data;
        error(errMessage);
      }
    })();

    return {
      abort: () => { // this function is called if the user has tapped the cancel button.
        source.cancel('Operation cancelled by the user.');
      },
    };

  }

  /**
   * Send a request to remove the file from the server.
   * NB: In the context of Static GTFSs, the server will only delete if it's a temporary file.
   */
  onFileRemove = async (fileData, load, error) => {
    load();

    await this.removeTempFile();
    await this.updateCurrentFileState(undefined);
  }

  private removeTempFile = async () => {
    const { http } = this.props.store.api;

    const { currentFile } = this.state;
    const url = this.getUrlFromToolTemplate();

    if (!currentFile) {
      return;
    }

    const requestConfig = {
      url,
      method: 'delete',
      params: {
        fileId: currentFile.fileId,
      },
      withCredentials: true,
    };

    await http.authenticatedRequest(requestConfig);
  }

  async updateCurrentFileState(currentFile?: UploadedFile) {
    const currentFileFromProps = this.getFileConfigFromProps();
    await this.setState({ currentFile });

    const currentFileChanged = !isEqual(currentFileFromProps, currentFile);
    if (currentFileChanged) {
      const fileConfig = this.serializeFileNameConfig();
      this.props.onChange(fileConfig, this.props.index);
    }
  }

  processError = err => err.body;

  render() {
    const { isLoadingFileData } = this.state;

    const filePondServerConfig = {
      process: this.uploadFile,
      revert: this.onFileRemove,
      remove: this.onFileRemove,
      load: null,
      fetch: null,
      restore: null,
    };

    const wrapperClassName = `download-upload-file-wrapper ${isLoadingFileData ? 'is-loading' : ''}`;

    return (
        <div className={wrapperClassName}>
          <FilePond
            ref={this.filePondRef}
            name="file"
            server={filePondServerConfig}
            labelFileProcessingError={this.processError} // tslint:disable
            allowMultiple={false}
            instantUpload={true}
          />

          {isLoadingFileData ? <BarLoader color="#30b566"/> : null}
        </div>
    );
  }
}

export default withStore<UploadDownloadUIProps>(UploadDownloadUI);
